import n from "react";
const A = () => {
  console.info("User not authorized! Redirecting to /min-sida"), window.location.href = "/min-sida";
};
function I(r, i) {
  if (!i)
    return !1;
  for (const s of r)
    if (i.includes(s))
      return !0;
  return !1;
}
const h = n.createContext(void 0);
function U({
  apiBackendBaseUrl: r,
  children: i,
  allowedRoles: s = [
    "teacher",
    "student",
    "digital_producent",
    "author",
    "editor",
    "contex_admin",
    "marketing",
    "product_reviewer"
  ]
}) {
  var l;
  const [t, c] = n.useState({ status: "unknown", user: null });
  function f() {
    c({ status: "unknown", user: null });
  }
  const a = n.useRef(!1);
  n.useEffect(() => {
    const e = async () => {
      const u = new AbortController(), w = setTimeout(() => u.abort(), 1e4), o = await fetch(`${r}/me`, {
        credentials: "include",
        signal: u.signal
      });
      if (clearTimeout(w), (o == null ? void 0 : o.status) === 401)
        t.status === "noauth" && (a.current = !0), c({ status: "noauth", user: null });
      else if (o && o.status >= 200 && o.status < 300) {
        const v = await o.json();
        a.current = !1, c({ status: "auth", user: v });
      } else
        console.error(`Request failed. Status: ${o == null ? void 0 : o.status}`);
    };
    !a.current && !(t != null && t.user) && e();
  }, [t, r]);
  const m = () => {
    console.info("User not authenticated! Redirecting to login");
    const e = window.location.pathname.substring(1), u = encodeURIComponent(
      `${e}${window.location.search}${window.location.hash}`
    );
    a.current = !0, window.location.href = `/login/?r=${u}`;
  }, d = n.useMemo(() => {
    const e = t == null ? void 0 : t.user;
    return e ? {
      isAuthenticated: !0,
      userId: e.userId,
      email: e.email,
      name: e.name,
      komvuxSchoolIds: e.komvuxSchoolIds,
      activeSchoolId: e.activeSchoolId,
      activeCustomerId: e.activeCustomerId,
      trialState: e.trialState,
      termsAccepted: e.termsAccepted,
      featureFlags: e.featureFlags,
      roles: e.roles
    } : {
      isAuthenticated: !1
    };
  }, [t.user]), g = n.useMemo(
    () => ({
      redirectToLogin: m,
      reload: f,
      user: d,
      pending: (t == null ? void 0 : t.status) === "unknown"
    }),
    [t == null ? void 0 : t.status, d]
  );
  return (t == null ? void 0 : t.status) === "auth" && !I(s, (l = t == null ? void 0 : t.user) == null ? void 0 : l.roles) && A(), /* @__PURE__ */ n.createElement(h.Provider, { value: g }, i);
}
const S = {
  user: {
    isAuthenticated: !1
  },
  redirectToLogin: () => {
    console.error("unAuthProvidedValues: redirectToLogin called - not supported");
  },
  reload: () => {
    console.warn("unAuthProvidedValues: reload called - not supported");
  },
  pending: !1
};
function k() {
  const r = n.useContext(h);
  return r === void 0 ? S : r;
}
export {
  h as AuthContext,
  U as AuthProvider,
  k as useAuth
};
