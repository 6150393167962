import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reportError, setTag } from '@gleerups/error-reporter';
import { useAuth } from '@gleerups/auth';
import { isNormalStudent } from '@gleerups/auth-core';
import { Heading1, Spacing, Breakpoint, IllustratedMessage, Link } from '@gleerups/syntax4';
import styled from 'styled-components';

import ProductsTabList from '../Products/ProductsTabList/ProductsTabList';
import ProductsSearchFilter from '../Products/ProductsSearchFilter/ProductsSearchFilter';
import LicensedProductsGrid from '../Products/LicensedProductsGrid/LicensedProductsGrid';
import { useTypedSelector } from '../store/rootReducer';
import {
  ProductErrorType,
  getAvailableProducts,
  getError,
  getLicensedProducts,
  getProductIdToActivate,
  getProductIdToShare,
  getTrialProducts,
  loadAvailableAndLicensedProducts,
  loadLicensedProducts,
  loadTrialProducts,
} from '../Products/productsReducer';

import LibraryModal from '../LibraryModal/LibraryModal';
import { loadEducationalStages } from '../EducationalStages/educationalStagesReducer';
import { RoutePaths } from '../routePaths';

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 3rem);
  padding-bottom: ${Spacing.XXXL};
`;

const Wrapper = styled.div`
  margin-bottom: ${Spacing.L};
`;

const StudentView = styled.div`
  margin: ${Spacing.M} auto 0;
  width: 75rem; /* 71rem + 4rem for margin (2rem on each side) */
  max-width: 100%;
  padding: 0 ${Spacing.XL};
  display: grid; /* to prevent margin collapse */

  @media (${Breakpoint.smallMax}) {
    padding: 0 ${Spacing.M};
  }
`;

const StyledHeading = styled(Heading1)`
  margin: 0 0 ${Spacing.M} 0;
`;

const StyledSearch = styled(ProductsSearchFilter)`
  margin-bottom: ${Spacing.M};

  @media (${Breakpoint.smallMax}) {
    margin-bottom: ${Spacing.XS};
  }
`;

const MaterialsView = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const params = useParams<{ productId: string }>();
  const { pathname } = useLocation();
  const error = useTypedSelector(getError);
  const licensedProducts = useTypedSelector(getLicensedProducts);
  const availableProducts = useTypedSelector(getAvailableProducts);
  const trialProducts = useTypedSelector(getTrialProducts);

  useEffect(() => {
    dispatch(loadEducationalStages());
  }, []);

  useEffect(() => {
    if (pathname.includes(RoutePaths.ACTIVATE_LICENSE) && !availableProducts.length) {
      dispatch(loadAvailableAndLicensedProducts());
      dispatch(loadTrialProducts(user.isAuthenticated));
    } else if (pathname.includes(RoutePaths.TRIAL) && !trialProducts.length) {
      dispatch(loadTrialProducts(user.isAuthenticated));
    } else if (!licensedProducts.length) {
      dispatch(loadLicensedProducts(false));
    }
    // Only need to check the first part of the route
  }, [pathname.split('/')[1]]);

  const productIdToActivate = useTypedSelector(getProductIdToActivate);
  const productIdToShare = useTypedSelector(getProductIdToShare);
  // is used to be able to direct link to the activation page for a product from the library shop on gleerups.se
  const { productId: productIdToActivateFromUrl } = params;
  const showLibraryModal = !!(
    productIdToActivateFromUrl ||
    productIdToActivate ||
    productIdToShare
  );

  const isStudent = isNormalStudent(user);

  if (error) {
    if (error === ProductErrorType.ProductUnauthorized) {
      // if user is not logged in => return nothing since user will be redirected to the login page
      return null;
    }
    setTag('source', 'ErrorBoundary');
    reportError(Error('Failed to fetch products'), {});
    return (
      <ErrorWrapper>
        <IllustratedMessage
          illustrationName="error"
          heading="Oj! Något gick fel"
          body={
            <span>
              Om problemet kvarstår, vänligen kontakta <Link href="/support">Gleerups Support</Link>
              .
            </span>
          }
        />
      </ErrorWrapper>
    );
  }

  return (
    <Wrapper>
      {isStudent ? (
        <StudentView>
          <StyledHeading>Läromedel</StyledHeading>
          <StyledSearch />
          <LicensedProductsGrid />
        </StudentView>
      ) : (
        <>
          <ProductsTabList />
          {showLibraryModal && <LibraryModal />}
        </>
      )}
    </Wrapper>
  );
};

export default MaterialsView;
